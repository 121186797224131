import * as React from 'react';
import * as ReactDOM from 'react-dom/client'; // Import ReactDOM from 'react-dom/client'
import { initThemeEditToolbar } from '../profile_theme/store';
import { EditThemeToolbar } from './EditThemeToolbar';
export function initEditThemeToolbar(mountPointId, domainJson) {
    var rootElement = document.getElementById(mountPointId);
    if (!rootElement) {
        throw new Error("No element found with the ID ".concat(mountPointId));
    }
    var root = ReactDOM.createRoot(rootElement); // Use the createRoot API
    var element = React.createElement(EditThemeToolbar, {});
    initThemeEditToolbar(domainJson);
    root.render(element); // Use root.render instead of ReactDOM.render
}

import * as React from 'react';
import { generateUUID } from "merchi_sdk_ts";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
export function Tooltip(_a) {
    var children = _a.children, tooltip = _a.tooltip;
    var ttid = generateUUID();
    return tooltip ? (React.createElement(React.Fragment, null,
        React.createElement(ReactTooltip, { content: tooltip, events: ['hover'], id: ttid }),
        React.createElement("span", { "data-tooltip-id": ttid }, children))) : (React.createElement(React.Fragment, null, children));
}
